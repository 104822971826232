import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import { BoxContent, CircularLoading } from "../../Atoms";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import useAuth from "../../../../hooks/context/useAuth";

import { useEffect, useState } from "react";
import { BasicPopover, GenericInput, GenericSelect } from "../../Molecules";
import { getUserAddress } from "../../../../services/addressService";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { differenceInDays } from "date-fns";
import { calcShipPrice } from "../../../../services/shippmentSimulation";
import { useCommonData } from "../../../../hooks/useCommonData";
import { amber } from "@mui/material/colors";

interface Props {
  loading: boolean;
  handleSubmit(val: any): void;
  getProducts(): Promise<Products[]>;
}

type SelectedPRD = {
  name: string;
  id: number;
  imgUrl: string;
  qtd: number;
  kg: number;
  stock: number;
  packageRemove: boolean;
  adminRemovePackage: boolean;
  arrivedAt?: Date;
}[];

export function NewShippmentBox({ handleSubmit, getProducts, loading }: Props) {
  const { getUserLocalStorage, siteConfig } = useAuth();
  const { shippmentModal, adminOrder } = useCommonData();
  const { JPYmoney } = useJPYmoney();
  const { user } = getUserLocalStorage();
  const [selectedProducts, setSelectedProducts] = useState([] as SelectedPRD);
  const [products, setProducts] = useState([] as Products[]);
  const [error, setError] = useState([] as SelectedPRD);
  const [address, setAddress] = useState<IAddress[]>();
  const [choiceIDAddress, setChoiceIDAddress] = useState("");
  const [choiceShippment, setChoiceShippment] = useState("smallPacket");
  const [shipError, setShipError] = useState({ errorMessage: "", open: false });
  const [checkBoxes, setCheckBoxes] = useState({
    plasticBubble: false,
    reinforcedBox: false,
    securityFee: false,
  });
  const [shipPrice, setShipPrice] = useState(
    {} as {
      weight: number;
      tax: number;
      shippingFee: number;
      total: number;
      taxRate: number;
    }
  );

  const myAddress = async () => {
    try {
      const myAddress = await getUserAddress(user?.user?.id);
      setAddress(myAddress);
    } catch (err) {}
  };

  const getUserPRDS = async () => {
    const prds = await getProducts();
    setProducts(prds);
  };

  useEffect(() => {
    getUserPRDS();
    myAddress();
    // eslint-disable-next-line
  }, []);

  const handleChangeQTD = (qtd: number, productID: number) => {
    setError((old) => old.filter((prd) => prd.id !== productID));
    let prds = selectedProducts;
    const filt = prds.findIndex((prd) => prd.id === productID);

    prds[filt] = {
      id: prds[filt]?.id,
      imgUrl: prds[filt]?.imgUrl,
      name: prds[filt]?.name,
      stock: prds[filt]?.stock,
      kg: prds[filt]?.kg,
      qtd,
      packageRemove: prds[filt]?.packageRemove,
      adminRemovePackage: prds[filt]?.adminRemovePackage,
      arrivedAt: prds[filt]?.arrivedAt,
    };

    setSelectedProducts(prds);
  };

  const submit = () => {
    const verify = selectedProducts.filter(
      (item) => item.stock < item.qtd || item.qtd === 0
    );

    const verifyQuantity = selectedProducts.filter((prd) => prd.qtd <= 0);
    // if (choiceIDAddress === "") {
    //   return;
    // }
    if (verify.length > 0) {
      return setError(verify);
    } else if (verifyQuantity.length > 0) {
      return setError(verifyQuantity);
    } else {
      const data = {
        shippmentType: choiceShippment,
        suiteId: user?.suite[0]?.id,
        addressID: Number(choiceIDAddress),
        plasticBubble: checkBoxes.plasticBubble,
        reinforcedBox: checkBoxes.reinforcedBox,
        securityFee: checkBoxes.securityFee,
        productsIDS: selectedProducts.map((prd) => ({
          id: prd?.id,
          shipQTD: prd?.qtd,
        })),
      };
      handleSubmit(data);
    }
  };

  const disableBTN = () => {
    if (selectedProducts.length === 0) return true;
    if (choiceIDAddress === "") return true;
    if (shipError.open) return true;
    return false;
  };

  const prdKG = selectedProducts.reduce(
    (acc, next) => acc + next.kg * next.qtd,
    0
  );

  const calcShip = async () => {
    if (!choiceIDAddress) return;
    if (choiceShippment === "DHL") return;
    try {
      const calc = await calcShipPrice({
        globalZoneId:
          address?.find((adr) => adr.id === Number(choiceIDAddress))
            ?.globalZoneId ?? 0,
        serviceType: choiceShippment,
        weight: prdKG + siteConfig?.defaultBoxWeight,
      });
      setShipPrice(calc.fee);
    } catch {}
  };

  const isLojista = user?.user?.needTaxOrders;
  const daysConfig = isLojista
    ? siteConfig?.daysFreeInStorageLojista ?? 0
    : siteConfig?.daysFreeInStorage ?? 0;

  const delayed = selectedProducts.reduce((acc, next) => {
    const daysInStock = Math.abs(
      differenceInDays(
        new Date(),
        new Date(next?.arrivedAt ?? "") ?? new Date()
      )
    );

    const daysLate = daysInStock - daysConfig;
    return acc + daysLate;
  }, 0);

  useEffect(() => {
    if (prdKG > 2000 && choiceShippment === "smallPacket") {
      return setShipError({
        errorMessage: adminOrder.limitWeightFirst,
        open: true,
      });
    }
    if (prdKG > 30000 && choiceShippment !== "smallPacket") {
      return setShipError({
        errorMessage: adminOrder.exceedMaximalWeight,
        open: true,
      });
    }
    calcShip();
    return setShipError({
      errorMessage: "",
      open: false,
    });

    // eslint-disable-next-line
  }, [prdKG, choiceShippment, choiceIDAddress]);

  const totalPrice = () => {
    const sumExtrasBuble = checkBoxes.plasticBubble
      ? siteConfig?.plasticBubblePrice
      : 0;
    const sumExtrasBox = checkBoxes.reinforcedBox
      ? siteConfig?.reinforcedBoxPrice
      : 0;

    const sumExtrasSecurityFee = checkBoxes.securityFee ? 500 : 0;
    const sumAll = sumExtrasBuble + sumExtrasBox + sumExtrasSecurityFee;

    if (delayed > 0) {
      return shipPrice?.total + sumAll + delayed * siteConfig?.stockPrice;
    }
    return shipPrice?.total + sumAll;
  };

  const addressToCopy = address?.find(
    (adr) => adr.id === Number(choiceIDAddress)
  );

  return (
    <BoxContent
      config={{
        minWidth: "180px",
        p: 3,
        minHeight: 300,
        justifyContent: "center",
      }}
    >
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <BasicPopover
            btnName={shippmentModal.popover}
            handleClickItem={(item) =>
              setSelectedProducts((old) => [
                ...old,
                { ...item, qtd: item?.stock === 1 ? 1 : 0 },
              ])
            }
            removeItemFromSelected={(item) =>
              setSelectedProducts((old) =>
                old?.filter((oldIds) => oldIds?.id !== item?.id)
              )
            }
            itemsSelected={selectedProducts}
            list={products?.map((item) => ({
              id: item?.id,
              name: item?.name,
              imgUrl: item?.ProductImages[0]?.code,
              stock: item?.leftInStock,
              kg: item?.kg,
              qtd: 0,
              packageRemove: item?.removePackage,
              adminRemovePackage: item?.adminRemovePackage,
              arrivedAt: item?.arrivedAt,
            }))}
          />

          {selectedProducts.length > 0 && (
            <Typography mb={3}>{shippmentModal.selectedItems}</Typography>
          )}
          <Box
            display="flex"
            flexWrap={"wrap"}
            gap={2}
            justifyContent={"center"}
          >
            {selectedProducts.map((item, index) => (
              <Card sx={{ maxWidth: 345 }} key={index}>
                <CardMedia
                  component="img"
                  height="194"
                  image={item?.imgUrl}
                  alt="Paella dish"
                />

                <IconButton
                  onClick={() =>
                    setSelectedProducts((old) =>
                      old?.filter((oldIds) => oldIds?.id !== item?.id)
                    )
                  }
                  aria-label="delete"
                  sx={{
                    position: "absolute",
                    bgcolor: "red",
                    marginTop: "-190px",
                    marginLeft: "155px",
                    zIndex: 12651651651561561,
                    color: "white",
                    "&:hover": { bgcolor: "pink" },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <GenericInput
                  placeholder={shippmentModal.quantity}
                  onChange={(qtd, id) => {
                    handleChangeQTD(Number(qtd), Number(id));
                  }}
                  inputType="number"
                  inputName={String(item?.id)}
                  hasIcon={false}
                  error={error.some((prdError) => prdError.id === item?.id)}
                  errorMessage={
                    error.some((prdError) => prdError.id === item?.id) &&
                    "Verify quantity"
                  }
                  value={
                    selectedProducts?.find(
                      (selected) => selected?.id === item?.id
                    )?.qtd
                  }
                />
                <Typography textAlign={"center"} mb={1}>
                  Stock {item?.stock}
                </Typography>
              </Card>
            ))}
          </Box>
          {shipError.open && (
            <FormHelperText
              error
              sx={{ bgcolor: amber[100], borderRadius: 2, p: 1, mt: 1 }}
            >
              <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
              {shipError.errorMessage}
            </FormHelperText>
          )}

          <Typography variant="h1" mt={2}>
            Extras
          </Typography>
          <Box display="flex" flexWrap={"wrap"} justifyContent={"center"}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkBoxes.reinforcedBox}
                  onChange={(e) => {
                    setCheckBoxes((old) => ({
                      ...old,
                      reinforcedBox: e.target.checked,
                    }));
                  }}
                />
              }
              label={`${shippmentModal.reinforcedBox} + ${JPYmoney(
                siteConfig?.reinforcedBoxPrice
              )}`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkBoxes.plasticBubble}
                  onChange={(e) => {
                    setCheckBoxes((old) => ({
                      ...old,
                      plasticBubble: e.target.checked,
                    }));
                  }}
                />
              }
              label={`${shippmentModal.plasticB}+ ${JPYmoney(
                siteConfig?.plasticBubblePrice
              )}`}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={checkBoxes.securityFee}
                  onChange={(e) => {
                    setCheckBoxes((old) => ({
                      ...old,
                      securityFee: e.target.checked,
                    }));
                  }}
                />
              }
              label={`${shippmentModal.securityFee}+ ${JPYmoney(500)}`}
            /> */}
          </Box>

          {choiceShippment === "DHL" ? (
            <Typography
              textAlign={"center"}
              sx={{ background: amber[100], p: 1, borderRadius: 2 }}
            >
              {shippmentModal.dhlChoice}
            </Typography>
          ) : (
            <Box width={"95%"}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>{shippmentModal.defaultBoxWeight}</Typography>
                <Typography>
                  {siteConfig?.defaultBoxWeight?.toLocaleString() ?? 0} g
                </Typography>
              </Box>
              <Divider />

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>{shippmentModal.itemsWeight}</Typography>
                <Typography>{prdKG?.toLocaleString()} g</Typography>
              </Box>
              <Divider />

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>{shippmentModal.totalWeight}</Typography>
                <Typography>
                  {(prdKG + siteConfig?.defaultBoxWeight)?.toLocaleString()} g
                </Typography>
              </Box>
              <Divider />

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>{shippmentModal.stockDays(daysConfig)}</Typography>
                <Typography>{delayed > 0 ? delayed : 0}</Typography>
              </Box>
              <Divider />

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>{shippmentModal.stockPrice}</Typography>
                <Typography>
                  {JPYmoney(
                    delayed > 0
                      ? delayed * siteConfig?.stockPrice
                      : 0 * siteConfig?.stockPrice
                  )}
                </Typography>
              </Box>
              <Divider />

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>{shippmentModal.serviceTax}</Typography>
                <Typography>
                  {prdKG > 0 && choiceIDAddress ? JPYmoney(shipPrice?.tax) : 0}
                </Typography>
              </Box>
              <Divider />

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>{shippmentModal.taxRate}</Typography>
                <Typography>
                  {prdKG > 0 && choiceIDAddress
                    ? JPYmoney(shipPrice?.taxRate)
                    : 0}
                </Typography>
              </Box>
              <Divider />

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>{shippmentModal.shippmentFee}</Typography>
                <Typography>
                  {prdKG > 0 && choiceIDAddress
                    ? JPYmoney(shipPrice?.shippingFee)
                    : 0}
                </Typography>
              </Box>
              <Divider />

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
                mb={1}
                mt={1}
              >
                <Typography>Total</Typography>
                <Typography>
                  {prdKG && choiceIDAddress ? JPYmoney(totalPrice()) : 0}
                </Typography>
              </Box>
              <Divider />
              <Typography textAlign={"center"} mb={1} mt={1}>
                {shippmentModal.creditAlert}
              </Typography>
            </Box>
          )}

          <GenericSelect
            onChange={(val) => {
              setChoiceIDAddress(val);
              setChoiceShippment("");
            }}
            options={
              address?.map((ad) => ({
                label: `${ad?.address} ${ad?.adressNumber} - ${ad?.city}/${ad?.country}`,
                value: String(ad?.id),
              })) ?? []
            }
            placeholder={shippmentModal.address}
            value={choiceIDAddress}
            name="address"
          />

          <GenericSelect
            onChange={(val) => setChoiceShippment(val)}
            options={[
              { label: "Small Packet", value: "smallPacket" },
              { label: "EMS", value: "ems" },

              { label: "Sal Air Parcel", value: "airParcel" },
            ].concat(
              addressToCopy?.country !== "Brazil"
                ? [{ label: "Sal Ship Parcel", value: "surface" }]
                : []
            )}
            placeholder={shippmentModal.shipChoice}
            value={choiceShippment}
            name="address"
          />

          <Button
            disabled={disableBTN()}
            sx={{ mt: 3 }}
            fullWidth
            variant="contained"
            onClick={submit}
          >
            {shippmentModal.create}
          </Button>
        </>
      )}
    </BoxContent>
  );
}
